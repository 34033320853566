<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Thông báo kế hoạch tổ chức hoạt động XT hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế
        </CCardHeader>
        <CCardBody>
<!--          <CRow>-->
<!--            <CCol lg="4">-->
<!--              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>-->
<!--            </CCol>-->
<!--            <CCol lg="4">-->
<!--              <CSelect label="Loại đơn vị" horizontal :options="optionsLoaiDonVi" :value.sync="filterModel.loaiDonVi"/>-->
<!--            </CCol>-->
<!--          </CRow>-->
<!--          <CRow>-->
<!--            <CCol lg="4">-->
<!--              <CInput label="Tên đơn vị" horizontal :value.sync="filterModel.tenDonVi"/>-->
<!--            </CCol>-->
<!--          </CRow>-->
          <CRow>
            <CCol lg="4">
              <CInput label="Số văn bản" horizontal :value.sync="filterModel.soVanBan"/>
            </CCol>
            <CCol lg="4" style="display: flex">
              <label>Từ ngày</label>
              <v-date-picker class="ml-5" v-model="filterModel.tuNgay" locale="vi">
                <template v-slot="{ inputValue, inputEvents }">
                  <CInput
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </CCol>
            <CCol lg="4" style="display: flex">
              <label>Đến ngày</label>
              <v-date-picker class="ml-5" v-model="filterModel.denNgay" locale="vi">
                <template v-slot="{ inputValue, inputEvents }">
                  <CInput
                    :value="inputValue"
                    v-on="inputEvents"
                    :placeholder="filterModel.denNgay"
                  />
                </template>
              </v-date-picker>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="4">
              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>
            </CCol>
            <CCol lg="6">
              <CInput label="Tên hoạt động" horizontal :value.sync="filterModel.tenHoatDong"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
<!--              <CButton class="mb-3 mr-3" color="secondary" :disabled="isFetchingList" @click="convertDateTime(filterModel.tuNgay)">-->
<!--                <CIcon name="cil-search"/>&nbsp;Conver time-->
<!--              </CButton>-->
              <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">
                <CIcon name="cil-search"/>&nbsp;Tìm kiếm
              </CButton>
              <CButton class="mb-3" color="primary" @click="createItem" v-show="showCreate">
                <CIcon name="cil-plus"/>&nbsp;Thêm mới
              </CButton>
            </CCol>
          </CRow>
          <div class="position-relative table-responsive">
            <table id="tbThongBaoKeHoach" class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr>
                <th class="position-relative pr-4">STT</th>
                <th class="position-relative pr-4">Số văn bản</th>
                <th class="position-relative pr-4">Tên hoạt động</th>
                <th class="position-relative pr-4">Ngày gửi</th>
                <th class="position-relative pr-4">Tình trạng</th>
                <th class="position-relative pr-4">Số lần hiệu chỉnh</th>
                <th class="position-relative pr-4">Trạng thái</th>
                <th class="position-relative pr-4">Thao tác</th>
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="dataSourcePaging.items">
                <tr v-for="(item, index) in dataSourcePaging.items" :key="item.id">
                  <td>{{ index + firstItemIndex + 1 }}</td>
                  <td>{{ item.soVanBan }}</td>
<!--                  <td>{{ displayLabel(optionsLoaiDonVi, item.loaiDonVi) }}</td>-->
                  <td>{{ item.tenHoatDong }}</td>
                  <td>{{ formatDateData(item.createdDate) }}</td>
                  <td>{{ item.tinhTrang }}</td>
                  <td>{{ item.soLanHieuChinh }}</td>
                  <td>
                    <template v-if="item.trangThai === 0">Tất cả</template>
                    <template v-if="item.trangThai === 1">Soạn thảo</template>
                    <template v-if="item.trangThai === 2">Chờ phê duyệt</template>
                    <template v-if="item.trangThai === 3">Đã phê duyệt</template>
                  </td>
                  <td>
                    <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết" v-show="showDetail">
                      <CIcon name="cil-options"/>
                    </CButton>
                    <CButton size="sm" color="primary" class="mr-2" v-if="item.trangThai != 3 && showUpdate" @click="updateItem(item)" title="Chỉnh sửa">
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="secondary" class="mr-2" v-if="item.trangThai == 2 && showApprove" @click="confirmApproveItem(item)" title="Phê duyệt">
                      <CIcon name="cil-settings"/>
                    </CButton>
                    <CButton size="sm" color="danger" @click="destroyItem(item)" title="Xóa" v-if="showDelete">
                      <CIcon name="cil-x"/>
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="dataSourcePaging.items && dataSourcePaging.items.length === 0 && !isFetchingList">
                <td colspan="8">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
            <CElementCover v-if="isFetchingList"
                           :boundaries="[{ sides: ['top'], query: 'td' },{ sides: ['bottom'], query: 'tbody' }]"/>
          </div>
        </CCardBody>
      </CCard>
      <DeleteConfirmationModal
        title="Xóa thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế"
        :show.sync="isDeleteConfirmationModalOpen"
        :is-deleting="isDeleting"
        :item="item" @confirm-destroy="confirmDestroy"/>
      <ApproveConfirmationModal
        title="Phê duyệt thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế"
        :show.sync="isApproveConfirmationModalOpen"
        :is-approving="isApproving"
        v-on:data="data($event)"
        :type="type"
        :item="approvingItem" @confirm-approve="confirmApprove"/>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'
import { loaiDonVis, statuses, displayLabel } from '@/shared/appConstants'
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {
  DELETE_THONGBAOKEHOACH, APPROVE_THONGBAOKEHOACH
} from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'
import moment from 'moment'

export default {
  name: 'ThongBaoKeHoach',
  component: [
    // Calendar,
    DatePicker
    // Datepicker
  ],
  data () {
    return {
      filterModel: {
        // loaiDonVi: '',
        // tenDonVi: '',
        // trangThai: null,
        soVanBan: '',
        trangThai: 0,
        // tuNgay: '2021-10-26T02:02:57.224Z',
        tuNgay: '',
        // denNgay: '2021-10-26T02:02:57.224Z',
        denNgay: '',
        tenHoatDong: '',
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      },
      optionsLoaiDonVi: loaiDonVis,
      optionsTrangThai: statuses,
      datePickerConfigs: {
        format: 'DD/MM/YYYY',
        lang: {
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            // MMM
            monthsShort: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            // dddd
            weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
            // ddd
            weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
            // dd
            weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
          }
        },
        clearable: true,
        inline: true,
        editable: false
      },
      tuNgay: '',
      denNgay: '',
      listItem: [],
      isDeleteConfirmationModalOpen: false,
      isApproveConfirmationModalOpen: false,
      approvingItem: {},
      type: 'VanBanPAXTDTTHDDNCCNC',
      information: {
        dinhKem: '',
        noiDung: '',
        id: 0
      },
      showCreate: false,
      showUpdate: false,
      showDetail: false,
      showApprove: false,
      showDelete: false
    }
  },
  watch: {
    'this.filterModel.tuNgay': function (val) {
      const date = new Date(val)
      this.filterModel.tuNgay = date.toISOString()
      // console.log(this.filterModel.tuNgay)
    }
  },
  computed: {
    ...mapGetters('thongBaoKeHoach', {
      dataSourcePaging: 'dataSourcePaging',
      isFetchingList: 'isFetchingList',
      responseResult: 'responseResult',
      isDeleting: 'isDeleting',
      item: 'detailItem',
      isApproving: 'isApproving'
    }),
    firstItemIndex () {
      return (this.filterModel.pageNumber - 1) * this.filterModel.pageSize || 0
    }
  },
  methods: {
    ...mapActions('thongBaoKeHoach', {
      getListPaging: types.GET_LIST_THONGBAOKEHOACH_PAGING,
      deleteItem: DELETE_THONGBAOKEHOACH,
      approveItem: APPROVE_THONGBAOKEHOACH
    }),
    displayLabel,
    async fetchListItem () {
      await this.getListPaging(this.filterModel)
    },
    async search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      // this.filterModel.tuNgay = this.convertDateTime(this.filterModel.tuNgay)
      // this.filterModel.denNgay = this.convertDateTime(this.filterModel.denNgay)
      await this.fetchListItem()
    },
    createItem () {
      this.$router.push({ path: 'thong-bao-ke-hoach-xt-hon-hop/them-moi' })
    },
    viewItem (item) {
      this.$router.push({ path: `thong-bao-ke-hoach-xt-hon-hop/${item.id}` })
    },
    updateItem (item) {
      this.$router.push({ path: `thong-bao-ke-hoach-xt-hon-hop/${item.id}/cap-nhat` })
    },
    destroyItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.isDeleteConfirmationModalOpen = true
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    confirmApproveItem (item) {
      this.approvingItem = Object.assign({}, item)
      this.isApproveConfirmationModalOpen = true
    },
    async confirmApprove () {
      await this.approveItem(this.information)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isApproveConfirmationModalOpen = false
        this.information.noiDung = ''
        this.information.dinhKem = ''
        await this.fetchListItem()
      }
    },
    data (event) {
      this.information.id = event.id
      this.information.noiDung = event.noiDung
      this.information.dinhKem = event.dinhKem
    },
    convertDateTime (val) {
      const date = new Date(val)
      const dateFormated = date.toISOString()
      // console.log(dateFormated)
      return dateFormated
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    },
    getRole () {
      const role = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : {}
      const permissions = role.permissions
      if (permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_ADD')) {
        this.showCreate = true
      } else this.showCreate = false
      if (permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_UPDATE')) {
        this.showUpdate = true
      } else this.showUpdate = false
      if (permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_DELETE')) {
        this.showDelete = true
      } else this.showDelete = false
      if (permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_APPROVE')) {
        this.showApprove = true
      } else this.showApprove = false
      if (permissions.includes('VanBanTBKHTCHDXTHHDTTMDLNGKT_VIEW')) {
        this.showDetail = true
      } else this.showDetail = false
    }
  },
  async mounted () {
    await this.getRole()
    await this.fetchListItem()
  }
}
</script>
